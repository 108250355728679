import { useSelector } from "react-redux";
import { selectVinCheckArray } from "./vdsCheckSlice";
import classes from "./backendDataInfoPage.module.css";

function VdsCheckTableCustom() {
  const vdsCheckArray = useSelector(selectVinCheckArray);

  function getRegistrationDetails(row) {
    function formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const formattedDate = date
        .toLocaleDateString("en-GB")
        .replace(/\//g, ".");
      const formattedTime = date.toLocaleTimeString("en-GB", { hour12: false });
      return `${formattedDate} ${formattedTime}`;
    }

    const registrations = [
      { label: "HCP5", date: `${formatDate(row.hcp5RegistrationDate)}` },
      { label: "HCP3", date: `${formatDate(row.hcp3RegistrationDate)}` },
      { label: "MIB3", date: `${formatDate(row.mib3RegistrationDate)}` },
      { label: "otaFc", date: `${formatDate(row.otaFcRegistrationDate)}` },
      { label: "conMod", date: `${formatDate(row.conModRegistrationDate)}` },
      { label: "gateway", date: `${formatDate(row.gatewayRegistrationDate)}` },
    ];

    const validRegistrations = registrations
      .filter((reg) => reg.date)
      .map((reg) => `${reg.label}: ${reg.date}`);

    return validRegistrations.length > 0
      ? validRegistrations
      : ["No registration found"];
  }

  return (
    <div className={classes.vdsTableContainer}>
      <div className={classes.vdsTable}>
        <div className={classes.vdsTableHeadContainer}>
          <div className={classes.vdsTableHeadCellICTO}>ICTO</div>
          <div className={classes.vdsTableHeadCellRegion}>Region</div>
          <div className={classes.vdsTableHeadCellEnvironment}>Environment</div>
          <div className={classes.vdsTableHeadCellCheck}>VIN Check</div>
          <div className={classes.vdsTableHeadCellRegistered}>Registered</div>

          <div className={classes.vdsTableHeadCellURL}>Base-URL</div>
        </div>
        <div className={classes.vdsTableBodyContainer}>
          {vdsCheckArray?.map((row, index) => {
            // Force NAR region to be treated as unknown when systemId is ICTO-25343
            const shouldSkipNar =
              row.region.toLowerCase() === "nar" &&
              row.systemId === "ICTO-25343";
            const isVehicleKnown = row.vehcileExists && !shouldSkipNar;

            return (
              <div
                className={classes.vdsTableBodyRow}
                style={{ userSelect: "text", cursor: "auto" }}
                key={`${row.environment}-${index}`}
              >
                <div
                  className={classes.vdsTableBodyCellICTO}
                  style={{ userSelect: "text", cursor: "auto" }}
                >
                  {row.systemId && isVehicleKnown ? row.systemId : "-"}
                </div>
                <div
                  className={classes.vdsTableBodyCellRegion}
                  style={{ userSelect: "text", cursor: "auto" }}
                >
                  {row.region.toUpperCase()}
                </div>
                <div
                  className={classes.vdsTableBodyCellEnvironment}
                  style={{ userSelect: "text", cursor: "auto" }}
                >
                  {row.environment.toUpperCase()}
                </div>

                <div
                  className={
                    isVehicleKnown
                      ? classes.vdsTableBodyCellAvailable
                      : classes.vdsTableBodyCellUnavailable
                  }
                >
                  {isVehicleKnown ? "known" : "unknown"}
                </div>

                <div
                  className={classes.vdsTableBodyCellRegistered}
                  style={{ userSelect: "text", cursor: "auto" }}
                >
                  {isVehicleKnown
                    ? getRegistrationDetails(row).map((detail, index) => (
                        <div
                          className={
                            detail !== "No registration found"
                              ? classes.vdsTableBodyCellRegisteredDate
                              : classes.vdsTableBodyCellNotRegisteredDate
                          }
                          key={index}
                        >
                          {detail}
                        </div>
                      ))
                    : "-"}
                </div>

                <div
                  className={classes.vdsTableBodyCellUrl}
                  style={{ userSelect: "text", cursor: "auto" }}
                >
                  {row.url && isVehicleKnown ? row.url : "-"}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default VdsCheckTableCustom;
