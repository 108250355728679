import React, { useEffect, useState } from "react";
import classes from "./pseudonymizer.module.css";
import { useDispatch, useSelector } from "react-redux";

import { FormControlLabel, RadioGroup, Radio, FormLabel } from "@mui/material";
import {
  setAudiencePseudonymizer,
  setEnvironmentPseudonymizer,
  setRegionPseudonymizer,
  setVinPseudonymizerManual,
  selectPresetEnvironmentPseudonymizer,
  selectPresetRegionPseudonymizer,
  selectEnvironmentPseudonymizer,
  selectRegiontPseudonymizer,
  selectVinPseudonymizerManual,
  selectAudiencePseudonymizer,
  selectPVinPseudonymizer,
  selectMbbUserIdPseudonymizer,
  selectPUserPseudonymizer,
  getpVINFromVINManual,
  selectResultContent,
  clearResultContent,
  selectPresetAudiencePseudonymizer,
} from "./pseudonymizerSlice";

function VinToPseudoVinInput() {
  const [invalidVINText, setInvalidVINText] = useState(false);
  const [invalidAudienceText, setInvalidAudienceText] = useState(false);
  const [resultText, setResultText] = useState("Result:");

  const dispatch = useDispatch();

  const envPseudonymizer = useSelector(selectEnvironmentPseudonymizer);
  const regPseudonymizer = useSelector(selectRegiontPseudonymizer);

  const vinPseudonymizer = useSelector(selectVinPseudonymizerManual);
  const audience = useSelector(selectAudiencePseudonymizer);
  const pVin = useSelector(selectPVinPseudonymizer);
  const userID = useSelector(selectMbbUserIdPseudonymizer);
  const pUser = useSelector(selectPUserPseudonymizer);

  const resultContent = useSelector(selectResultContent);

  const pseudoVinFromVinHandler = (
    dispatch,
    environment,
    region,
    vin,
    pVin,
    mbbUserId,
    pUser,
    audience
  ) => {
    dispatch(
      getpVINFromVINManual({
        environment,
        region,
        vin,
        pVin,
        mbbUserId,
        pUser,
        audience,
      })
    );
  };

  useEffect(() => {
    if (!vinPseudonymizer) {
      dispatch(setVinPseudonymizerManual(""));
    }
    if (!vinPseudonymizer && !pVin && !audience && !userID && !pUser) {
      dispatch(clearResultContent());
      setInvalidAudienceText(false);
      setInvalidVINText(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultContent]);
  return (
    //
    // Audience Input section
    //
    <>
      <div className={classes.audienceInputSection}>
        <div
          className={classes.audienceInputSectionText}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          Audiences
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "100%" }}>
            <RadioGroup
              aria-labelledby="audience-radio-buttons-group-label"
              value={audience || ""}
              onChange={(event) => {
                dispatch(setAudiencePseudonymizer(event.target.value));
                setInvalidAudienceText(false);
              }}
              name="radio-buttons-group"
            >
              {/* REL3 (MLBEVO) */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel>
                  <div
                    className={classes.audienceInputSectionText1}
                    style={{ userSelect: "text", cursor: "auto" }}
                  >
                    REL3 (MLBEVO):
                  </div>
                </FormLabel>
                <FormControlLabel
                  value="t1-v_p:odc|porsche|default|vehicle"
                  control={<Radio size="small" />}
                  label="odc"
                />
                <FormControlLabel
                  value="t1-v_p:here_sparta|porsche|default|vehicle"
                  control={<Radio size="small" />}
                  label="here_sparta"
                />
                <FormControlLabel
                  value="t1-v_p:onlinespeech|porsche|default|vehicle"
                  control={<Radio size="small" />}
                  label="onlinespeech"
                />
              </div>

              {/* REL4 (PPE) */}
              <div style={{ display: "flex", alignItems: "center"}}>
                <FormLabel>
                  <div
                    className={classes.audienceInputSectionText1}
                    style={{ userSelect: "text", cursor: "auto" }}
                  >
                    REL4 (PPE):
                  </div>
                </FormLabel>
                <FormControlLabel
                  value="speechota.prod|default|default|gauth"
                  control={<Radio size="small" />}
                  label="speechota"
                />
              </div>
            </RadioGroup>
          </div>
        </div>
        <div className={classes.actionBtnSearchInvisible}></div>

      </div>

      {/* END of Audience input section  */}


      {/* //---------------------------------------------------------------------- */}
      {/* // // //VIN input section // */}
      <div style={{marginBottom: "5rem"}}></div>
      
      <div className={classes.pseudoVINInputSection}>
        <div
          className={classes.pseudoVINInputSectionText}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          {" "}
          VIN:{" "}
        </div>
        {/*  */}
        <div className={classes.pseudoVinSearchContainer}>
          <input
            id="searchVIN"
            autoComplete="off"
            required
            data-cy="searchInput"
            value={vinPseudonymizer || ""}
            onChange={(event) => {
              dispatch(setVinPseudonymizerManual(event.target.value));
              setInvalidVINText(false);
            }}
            style={{ userSelect: "text", cursor: "auto" }}
            readOnly={false}
          ></input>
          <label
            htmlFor="searchVIN"
            className={classes.searchLabel}
            data-cy="searchLabel"
            style={{ userSelect: "text", cursor: "auto" }}
          ></label>
        </div>
        <div
          className={classes.actionBtnSearchForPseudoVin}
          onClick={() => {
            if (!vinPseudonymizer) {
              setInvalidVINText(true);
            } else if (!audience) {
              setInvalidAudienceText(true);
            } else {
              pseudoVinFromVinHandler(
                dispatch,
                envPseudonymizer,
                regPseudonymizer,
                vinPseudonymizer,
                "",
                "",
                "",
                audience
              );
              setResultText("Pseudo VIN for VIN:");
              setInvalidVINText(false);
            }
          }}
        >
          Get pVIN
        </div>
      </div>
      {invalidVINText ? (
        <div className={classes.invalidValueWarning}>
          Please check audience & VIN
        </div>
      ) : invalidAudienceText ? (
        <div className={classes.invalidValueWarning}>
          Audience field is required
        </div>
      ) : (
        <div className={classes.invalidValueWarning}></div>
      )}
      {/*  */}
      {/* END of VIN section */}
      {/*  */}
    </>
  );
}

export default VinToPseudoVinInput;
