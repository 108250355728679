import React, { useRef, useState } from "react";
import classes from "./CcpGenerator.module.css";
import { generateCCPFile } from "./ccpGeneratorSlice";
import { useDispatch } from "react-redux";

function UploadCSV() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const uploadHandler = () => {
    if (selectedFile) {
      dispatch(generateCCPFile(selectedFile));
      setFileName("");
    } else {
      console.log("No file selected");
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/CCP_CGW_DATASET_FINAL.xlsx";
    link.setAttribute("download", "CCP_CGW_DATASET_FINAL.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAvailable =
    selectedFile !== null
      ? classes.ccpGeneratorDownloadContentsActionSectionSelectButton
      : classes.ccpGeneratorDownloadContentsActionSectionSelectButtonDisabled;

  return (
    <div className={classes.ccpGeneratorUploadContentsInputFields}>
      <div className={classes.ccpGeneratorUploadContentsActionSection}>
        <div className={classes.ccpGeneratorUploadContentsActionSectionInfo}>
          Upload CSV file. XML Files Download will automatically start
        </div>
        <div className={classes.ccpGeneratorUploadContentsActionSectionSelect}>
          <input
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <div
            className={
              classes.ccpGeneratorUploadContentsActionSectionSelectButton
            }
            onClick={() => fileInputRef.current.click()}
          >
            Upload CSV
          </div>
          <div className={downloadAvailable} onClick={uploadHandler}>
            Download CCP .xml files
          </div>
        </div>
      </div>
      {fileName !== "" ? (
        <div className={classes.fileNameBox}>File selected: {fileName}</div>
      ) : (
        <div></div>
      )}{" "}
      <div className={classes.ccpExampleContainer}>
        <p className={classes.ccpExampleText}>
          {" "}
          INFO: Only for CGW ECUs an unique CSV style is required for creating
          CCP Files. An example can be downloaded{" "}
        </p>
        <p onClick={handleDownload} className={classes.ccpExampleButton}>
          here
        </p>
      </div>
    </div>
  );
}

export default UploadCSV;
